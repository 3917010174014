:root{
  --h-menu: 10vh;
  --h-main: 80vh;
  --h-footer: 10vh;
}

@media only screen and (max-width: 768px) {
  :root{
    --h-menu: 8vh;
    --h-main: 84vh;
    --h-footer: 8vh;
  }
}

html{
  /*Para retirar o espaço ocupado pelo scroll qdo ele não aparece*/
  /*scrollbar-gutter: stable;*/
}

body {
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


/*MAIN*/


.menu {
  height: var(--h-menu);
}

.main{
 padding-top: var(--h-menu);
 min-height: var(--h-main);
}

.footer{
  height: var(--h-footer);
}

.text-justify{
  text-align: justify;
}

.no-background{
  background:none !important;
}

.navbar-toggler:focus{
  box-shadow: none;;
}

/*Filme*/
.ano-duracao-filme{
  font-size: medium;
}

.small-logo{
  filter: grayscale(25%);
}

.small-logo:hover{
  transform: scale(1.05);
  transition: transform 0.2s;
  filter: grayscale(0);
}

.object-fit-contain{
  object-fit: contain;
}

.object-fit-cover{
  object-fit: cover;
}

.btn-scroll-top{
  font-size: 4vh;
  margin-bottom: -1rem;
  margin-left: auto;
  color: var(--bs-white);
  cursor: pointer;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}


.movie-imgs-swipper{
  background-color: #eaeaea;
}

.player{
  height: 100% !important;
 background-color: black;
}

.md-text > p {
  margin-bottom: 0;
}

.ficha-tecnica-ul{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
