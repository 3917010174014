@import "./styles/variables";

.navbar a.nav-link{
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .navbar a.nav-link{
    display: inline;

  }
  
  .navbar li:not(:last-child):after {
    content: "";
    border-right: 1px solid #eaeaea;
    align-self: center;
    height: 30%;
  }
}

