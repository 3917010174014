$white: #FFF;
$black: #000;
$info: yellow;
$dark: $black;

$link-decoration: none;
$font-size-base: 0.9rem;

/*navlink*/
$navbar-nav-link-padding-x: 1.5rem;
$navbar-padding-y: 0;
$link-color: $black;

@import 'bootstrap/scss/bootstrap.scss';

 