#app {
    height: 100%;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .small-slide-img{
   
  }

  .swiper-slide .small-slide-img:hover{
    cursor: pointer;
    transform: scale(1.02);
    transition: transform 0.3s;
    filter: grayscale(0.2);
  }
  
  .swiper-slide img {
    width: 100%;
    height: 100%;
  }
  